import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  FrontPage,
  POSManagement,
  FrontFullPage,
  POSSummary,
  DetailPOS,
} from '../views/POS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotFound, NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';
import EditDetailPOS from '../views/POS/EditDetailPOS';

export function POS() {
  const module = 'POS';
  const prefix = '/pos';
  const name = 'จัดการขายหน้าร้าน';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: SiteInfo');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <>
        <Switch>
          {accessRight(me, module, 1, availableModule) && (
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/main-front`} />
              <Route exact path={`${prefix}/main-front`}>
                <div className="min-h-screen">
                  <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
                  <div className="min-h-screen  pt-16 px-4 flex w-full">
                    <MainSidebar
                      onMobileClose={handleOnMobileNavClose}
                      openMobile={isMobileNavOpen}
                      me={me}
                    />
                    <div className="py-4 w-full">
                      <FrontPage title="จัดการหน้าร้าน" subtitle={name} />
                    </div>
                  </div>
                  <div className="lg:ml-64">
                    <MainFooter />
                  </div>
                </div>
              </Route>
              <Route exact path={`${prefix}/exports/:id`}>
                <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
                <div className="min-h-screen  pt-16 px-4 flex w-full">
                  <MainSidebar
                    onMobileClose={handleOnMobileNavClose}
                    openMobile={isMobileNavOpen}
                    me={me}
                  />
                  <div className="py-4 w-full">
                    <DetailPOS title="รายละเอียดการขาย" subtitle={name} />
                  </div>
                </div>
                <div className="lg:ml-64">
                  <MainFooter />
                </div>
              </Route>
              <Route exact path={`${prefix}/pos-management`}>
                <div className="min-h-screen">
                  <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
                  <div className="min-h-screen  pt-16 px-4 flex w-full">
                    <MainSidebar
                      onMobileClose={handleOnMobileNavClose}
                      openMobile={isMobileNavOpen}
                      me={me}
                    />
                    <div className="py-4 w-full">
                      <POSManagement
                        title="รายการสั่งซื้อหน้าร้าน"
                        subtitle={name}
                      />
                    </div>
                  </div>
                  <div className="lg:ml-64">
                    <MainFooter />
                  </div>
                </div>
              </Route>
              <Route exact path={`${prefix}/pos-management/edit/:id`}>
                <div className="min-h-screen">
                  <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
                  <div className="min-h-screen  pt-16 px-4 flex w-full">
                    <MainSidebar
                      onMobileClose={handleOnMobileNavClose}
                      openMobile={isMobileNavOpen}
                      me={me}
                    />
                    <div className="py-4 w-full">
                      <EditDetailPOS
                        title="แก้ไขรายการสั่งซื้อหน้าร้าน"
                        subtitle={name}
                      />
                    </div>
                  </div>
                  <div className="lg:ml-64">
                    <MainFooter />
                  </div>
                </div>
              </Route>
              <Route exact path={`${prefix}/pos-summary`}>
                <div className="min-h-screen">
                  <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
                  <div className="min-h-screen  pt-16 px-4 flex w-full">
                    <MainSidebar
                      onMobileClose={handleOnMobileNavClose}
                      openMobile={isMobileNavOpen}
                      me={me}
                    />
                    <div className="py-4 w-full">
                      <POSSummary
                        title="สรุปรายการสั่งซื้อหน้าร้าน"
                        subtitle={name}
                      />
                    </div>
                  </div>
                  <div className="lg:ml-64">
                    <MainFooter />
                  </div>
                </div>
              </Route>
              <Route exact path={`${prefix}/main-front/full`}>
                <FrontFullPage
                  title="จัดการหน้าร้าน (เต็มจอ)"
                  subtitle={name}
                />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          )}
          <Route path="*">
            <NotAuthorized />
          </Route>
        </Switch>
      </>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default POS;
