import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import ImageUpload from '../../components/ImageUpload/ImageUpload';

export default function EditDetailPOS({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const productTransaction = useSelector((state) => state.productTransaction);

  const [addImage, setAddImage] = useState([]);
  console.log('addImage', addImage);

  useEffect(() => {
    dispatch(actions.productTransactionGet(id));

    return () => {};
  }, [id]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const onSubmit = async (data) => {
    console.log('data', data);
    try {
      if (addImage[0].data_url) {
        const payload = {
          ...data,
          slip: {
            image: addImage[0]?.data_url,
            imageType: '',
            alt: '',
          },
        };
        console.log('payload', payload);
        await dispatch(actions.productTransactionPut(id, payload));
        await dispatch(actions.productTransactionGet(id));
        setAddImage([]);
        alert('สำเร็จ');
        history.goBack();
      } else {
        alert('ไม่ได้ใส่รูปภาพใหม่มา');
      }
    } catch (error) {
      console.log(error);
      alert(`ไม่สามารถดำเนินการได้ ${error?.message}`);
    }
  };
  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
        <div className="text-lg font-semibold ml-6">
          แก้ไขรายการสั่งซื้อหน้าร้าน
        </div>
      </div>
      <div>
        <Card className="p-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full px-1 py-2 justify-center">
              <ImageUpload
                images={addImage}
                setImages={setAddImage}
                preview_size="250"
                maxNumber={10}
              />
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
              >
                บันทึก
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
  return <Error />;
}

EditDetailPOS.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditDetailPOS.defaultProps = {
  title: '',
  subtitle: '',
};
