/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import appConfig from '../../configs/app';
import dayjs from 'dayjs';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'วันที่',
    value: (row) => `${dayjs(row.date).format('DD/MM/YY')}`,
  },
  {
    label: 'ลูกค้า',
    value: (row) => `${row?.customer?.type?.name || '-'}`,
  },
  {
    label: 'สาขาวิชา / หน่วยงาน',
    value: 'type_code',
  },
  {
    label: 'รายการ',
    value: (row) => `${row?.product?.name}`,
  },
  {
    label: 'สี',
    value: 'name',
  },

  {
    label: 'จำนวน(ชิ้น/ชุด)',
    value: 'amount',
  },
  {
    label: 'รายการชำระ',
    value: (row) =>
      `${
        (row?.payment_type === 'cash' && 'เงินสด') ||
        (row?.payment_type === 'transfer' && 'โอน')
      }`,
  },
  {
    label: 'ต้นทุน',
    value: 'cost_price',
  },
  {
    label: 'ราคา(ชิ้น/ชุด)',
    value: 'price',
  },
  {
    label: 'ส่วนลด',
    value: 'discount_bill',
  },
  {
    label: 'ยอดเงิน',
    value: 'total_price_offline_out_before',
  },
  {
    label: 'เลขที่ใบเสร็จ',
    value: (row) => `${row?.bill_no || '-'}`,
  },
  {
    label: 'หมายเหตุ',
    value: 'remark',
  },
];

const query = ({ id }) => ({
  place: id,
  page: 1,
  size: appConfig?.maxFetchSize,
  DashBoardPage: false,
  type: 'pos',
});

export default { columns, query };
