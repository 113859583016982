/* eslint-disable no-confusing-arrow */
import appConfig from '../../configs/app';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รหัสสินค้า',
    value: 'type_code',
  },
  {
    label: 'ขื่อวัตถุดิบ',
    value: 'name',
  },
  {
    label: 'คงเหลือ',
    value: 'inventory',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: false,
  fetchProcess: false,
  page: 1,
  size: appConfig.maxFetchSize,
});

export default { columns, query };
